import { template as template_6717a0ac180f495eae95f3cde20d0ce4 } from "@ember/template-compiler";
const WelcomeHeader = template_6717a0ac180f495eae95f3cde20d0ce4(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
