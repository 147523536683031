import { template as template_18b13ac5e9cc44c6b7d746463bd7df16 } from "@ember/template-compiler";
const FKText = template_18b13ac5e9cc44c6b7d746463bd7df16(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
