import { template as template_5c0cb37735554e7fa8a707f30acc2e2b } from "@ember/template-compiler";
const FKControlMenuContainer = template_5c0cb37735554e7fa8a707f30acc2e2b(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
